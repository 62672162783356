<template>
  <v-col cols="12" md="3">

    <v-card-title>
      <v-avatar class="mr-1 text-center">
        <img
            alt="John"
            src="https://cdn.vuetifyjs.com/images/john.jpg"
            :src="application.applicant_photo ? application.applicant_photo['thumb'] : `https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${application.applicant}`"
        >
      </v-avatar>
      {{ application.applicant }}
    </v-card-title>
    <div class=" ">
      <div class="">
        <div v-if=" application.application_type == 'academic' && application && application.institution_logo.real">
          <img
              style="max-width:100%; object-fit:cover;max-height:10rem;"
              :alt="application.institution"
              :src="application.institution_logo.real"
          />
        </div>

        <div class="">
          <div class=" subtitle" v-if="application.application_type == 'academic'">
            <div class="title">
              {{ application.institution }}
            </div>

          </div>
          <div class=" subtitle-1" v-if="application.application_type == 'visa'">
            {{ application.applicant }}
          </div>
          <div class=" subtitle-1">
            <strong> Session : </strong> <span
              class="badge badge-primary text-uppercase">{{ application.academic_session }}</span>
          </div>
          <div class="body mt-2">

            <div class="">
              <template v-if="application.application_type == 'academic'">
                <strong> Applied Institution : </strong>
                {{ application.institution }}
                <br/>
              </template>
              <template v-if="application.country_name">
                <strong> Applied Country : </strong>
                {{ application.country_name }}<br/>
              </template>

              <template v-if="application.academic_program">
                <strong> Applied Program : </strong>
                <span v-html="application.academic_program_title_location"></span>
                <br/>
              </template>

              <strong> Application Reference No: </strong>
              {{ application.reference_id ? application.reference_id : '####' }}
              <br/>

              <template v-if="application.institution_country != 'Canada'">
                <strong> Medical Examination Date : </strong>
                <span class="badge badge-success">
                               {{ application.formatted_medical_date!='' ? application.formatted_medical_date : 'N/A' }}
                            </span>
                <br>
              </template>
              <template v-if="application.institution_country != 'Canada'">
                <strong> Biometric Date : </strong>
                <span class="badge badge-success">
                               {{ application.formatted_biometric_date }}
                            </span>
                <br>
              </template>
              <template
                  v-if="application.formatted_visa_decision_date">
                <strong> Visa Decision Date : </strong>
                <span class="badge badge-success">
                               {{ application.formatted_visa_decision_date }}
                            </span>
                <br>
              </template>
              <template
                  v-if="application.formatted_created_date">
                <strong> Application Date: </strong>
                <span class="badge badge-success">
                               {{ application.formatted_created_date }}
                            </span>
                <br>
              </template>
              <template
                  v-if="application.formatted_visa_application_date">
                <strong> Visa Application Date: </strong>
                <span class="badge badge-success">
                               {{ application.formatted_visa_application_date }}
                            </span>
                <br>
              </template>
              <template
                  v-if="application.formatted_last_open_date">
                <strong> Last Open Date : </strong>
                <span class="badge badge-success">
                               {{ application.formatted_last_open_date }}
                            </span>
                <br>
              </template>
              <template
                  v-if="application.application_type == 'visa' && application.nomination_category_title">
                <strong> Nomination Category : </strong>
                <span class="badge badge-success">
                               {{ application.nomination_category_title }}
                            </span>
                <br>
              </template>
              <template
                  v-if="application.application_type == 'visa' && application.nomination_sub_class_title">
                <strong> Nomination Sub Class : </strong>
                <span class="badge badge-success">
                               {{ application.nomination_sub_class_title }}
                            </span>
                <br>
              </template>
              <template
                  v-if="application.application_type == 'visa' && application.skill_occupation_title">
                <strong> Skill Occupation : </strong>
                <span class="badge badge-success">
                               {{ application.skill_occupation_title }}
                            </span>
                <br>
              </template>
              <template
                  v-if="application.application_type == 'visa' && application.application_permit_title">
                <strong> Application permit : </strong>
                <span class="badge badge-success">
                               {{ application.application_permit_title }}
                            </span>
                <br>
              </template>
              <template
                  v-if="application.application_type == 'academic' && application.education_level">
                <strong> Level : </strong>
                {{ application.education_level }}
                <br/>
              </template>
              <div v-if="application.visa_decision_date">
                <p><b> Visa decision date : </b>{{application.formatted_visa_decision_date}}</p>
              </div>
              <div v-if="application.visa_decision_date && application.application_status">
                <p><b> Visa decision status : </b>
                  <v-chip small
                          dark
                          :color="application.application_status=='granted' ? 'green' : application.application_status=='visa_applied' ? 'primary' : 'red'"
                          v-text="application.application_status_text"></v-chip>
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <v-divider class="my-2"></v-divider>
      <div class="d-flex my-3 justify-between align-items-center"
           v-if="application.application_type == 'academic'">
        <div class="">
          <div class="d-flex mt-2 justify-between">
            <b> Ready to submit </b>
            <v-chip class="ml-5" small
                    dark
                    :color="application.is_ready_to_submit ? 'green' : 'red'"
                    v-text="application.is_ready_to_submit?'YES':'NO'"></v-chip>
          </div>

          <div class="d-flex mt-2 justify-between">
            <b> Valid for Offer </b>
            <v-chip class="ml-5" small
                    dark
                    :color="application.is_valid_offer ? 'green' : 'red'"
                    v-text="application.is_valid_offer?'YES':'NO'"></v-chip>
          </div>

          <div class="d-flex mt-2 justify-between">
            <b> Submitted to Institution </b>
            <v-chip class="ml-5" small
                    dark
                    :color="application.is_submitted_school ? 'green' : 'red'"
                    v-text="application.is_submitted_school?'YES':'NO'"></v-chip>
          </div>

          <div class="d-flex mt-2 justify-between">
            <b> Offer Issued </b>
            <v-chip class="ml-5" small
                    dark
                    :color="application.is_ready_enroll ? 'green' : 'red'"
                    v-text="application.is_ready_enroll?'YES':'NO'"></v-chip>
          </div>
          <div class="d-flex mt-2 justify-between">
            <b> Fee Payment Received </b>
            <v-chip class="ml-5" small
                    dark
                    :color="application.is_enrolment_confirmed ? 'green' : 'red'"
                    v-text="application.is_enrolment_confirmed?'YES':'NO'"></v-chip>
          </div>
          <div class="d-flex mt-2 justify-between">
            <b> Ready for Visa </b>
            <v-chip class="ml-5" small
                    dark
                    :color="application.is_ready_for_visa ? 'green' : 'red'"
                    v-text="application.is_ready_for_visa?'YES':'NO'"></v-chip>
          </div>

        </div>
      </div>
      <div class="title-2" v-if="user_setting && user_setting.citizen_of">
        <b> Country </b> {{ user_setting.citizen_of }}
      </div>
      <v-divider class="my-2"></v-divider>
      <template v-if="test_scores && test_scores.length>0">
        <div class="d-flex my-3 ">
          <b class="mr-3">English Test </b>
          <template v-if="test_scores && test_scores.length>0" class="mx-2">
            <v-chip :key="index"
                    small
                    color="primary"
                    v-for="(score, index) of test_scores">
              {{ `${score.exam_type_text} - ${score.total}` }}
            </v-chip><br>
          </template>

        </div>
      </template>
      <template v-if="application.current_application_status">

        <div class="d-flex my-3 justify-between align-items-center">
          <div class="shrink">
            <b> Applied for </b> {{ application.current_application_status }}
          </div>
        </div>
        <v-divider class="my-2"></v-divider>
      </template>

      <v-divider class="my-2"></v-divider>
      <div class="title-2">
        <v-btn @click="deleteApplication(application.id)" style="width: 100%"
               class="text-white" depressed
               color="red">
          <v-icon>fas fa-ban</v-icon>
          Remove Application
        </v-btn>
        <v-btn @click="submitApplication(application.id)" style="width: 100%"
               class="mt-2 text-white" depressed
               color="success"
               v-if="application.is_draft && !submitted_application"
        >
          <v-icon>fas fa-check</v-icon>
          Submit Application
        </v-btn>
      </div>

    </div>

  </v-col>
</template>

<script>

export default {
  name: "Info",
  props: ['application', 'user_setting', 'test_scores', 'submitted_application'],
  methods: {
    deleteApplication(id) {
      this.$emit("delete-application", id)
    },
    submitApplication(id) {
      let data = {
        id: id,
        is_submitted: 1,
        is_ready_to_submit: 1,
        is_draft: 0,
        is_offer_letter: 0,
        is_complete: 0,
        is_deferred: 0,
        is_refund: 0,
        is_archived: 0,
      }
      this.$emit("submit-application", data)
    }
  }
}
</script>